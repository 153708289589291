.div-carousel{
    width:90%;
}
.lightbackground{
    background-color: rgba(30, 44, 108, 0.683);
    padding: 10px;
    border-radius: 20px;
}
@media only screen and (min-width: 991px) {
    .div-carousel{
        width:65%
    }
}