.masked-input-div{
    background-color: var(--white);
    padding: 10px;
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--grey);
    transition: 0.15s;
    margin-top: 10px;
}
.masked-input-div input{
    width: 100%;
    border: 0;
    outline: 0;
}
.masked-input-div>label{
    position: absolute;
    top: -9px;
    left: 15px;
    font-size: 12px;
    color: var(--darkgrey);
}
.masked-input-div>.border-hidder{
    height: 5px;
    background-color: var(--white);
    position: absolute;
    top:-1px;
    left:10px;
}
.masked-input-div:focus-within{
    border: 1px solid rgb(45, 90, 255);
}
.masked-input-div:focus-within>label{
    color: rgb(45, 90, 255);
}
