
.container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    overflow:hidden;
    padding-right: 50px;
    
}

.leftcolumn {
    width: 60%;
    margin-top: 60px;
    text-align: center;
    position: relative;   
    float:left;  
}
   
.rightcolumn {
    width: 40%;
    margin-top: 60px;
    justify-content: space-between;
    float:left;
}

.imgcarousel{
    width: 95%;
    margin-top:20px;
    margin-bottom:10px;
}


.imgcarousel img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.productdescription{
    margin-bottom: 10px;
}

.uploadimg{
    opacity: 0.6;
}


.productdescription span {
    font-size: 12px;
    color: var(--first-color);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}
.productdescription h1 {
    font-weight: 300;
    font-size: 52px;
    color: var(--darkergrey);
    letter-spacing: -2px;
    margin-bottom: 30px;
  }
.productdescription p {
    font-size: 16px;
    font-weight: 300;
    color: var(--darkgrey);
    line-height: 24px;
}

.productprice {
    display: flex;
    flex-direction: column;
}
.values{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

}
.values>*{
    margin-top: 10px;

}

.leftcolumntext{
    margin-right:50px;
}
.leftcolumnbuttons{
    margin-right:60px;
}
.rightcolumnbuttons{
    margin-top:20px;
}
@media screen and (max-width: 770px) {
    .leftcolumn{
        float: none;
        margin-right:0;
        width:auto;
        border:0;
    }
    .rightcolumn{
        float: none;
        margin-right:0;
        width:auto;
        border:0;

    }
    .imgcarousel{
        width:auto;
    }

    .leftcolumntext{
        margin-right:0px;
    }
    .leftcolumnbuttons{
        margin-right:0px;
    }
    .rightcolumnbuttons{
        margin-top:40px;
    }

    .container{
        padding-right:15px;
        margin-top:-30px;
    }
    
}
