.links{
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    opacity: 0.7;
}
.searchbar {
  border-radius: 0px;
  border: none;
  padding-left: 0px;
  padding-bottom: 0px;
  outline: none;
}

.searchbar:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.links::after{
  content: "";
  display: block;
  width: 0;
  height: 2px;
  margin-top: -2px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}

.links:hover:after{
    width:100%;
}

.links:hover{
    opacity:1;
}

input.mantine-Input-input.mantine-TextInput-input.mantine-1e0o9z1{
    padding-left: 1.5rem;
}

.input-wrapper {
  transition-delay: 0.3s;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  height: 35px;
  padding-right: -10px;
}

.animation-focus{
    width:auto;
}
.animation-focus::after{
    content: "";
  display: block;
  width: 0;
  height: 2px;
  margin-top: -2px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}

.animation-focus:hover:after{
    transition-delay: 0.4s;
    width:100%;

}


.input-wrapper::after {
    content: "";
  display: block;
  width: 0;
  height: 2px;
  margin-top: -4px;
  background: rgba(0, 0, 0, 0.3);
  content: "";
  display: block;
  width: 0;
  height: 2px;
  margin-top: 1px;
  background: rgba(251, 251, 251, var(--mdb-bg-opacity));
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -o-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}

.input-wrapper:hover:after {
    width: 100%;
}

 .logo-background{
   width:89px;
  }


.botaosearch {
  margin-top: 14px;
  height: 37px;
  margin-right: 20px;
}

.logobackground{
    display:none;
        margin-top: -9px;
        margin-bottom: -9px;
        width: 90px;
        height: 80px;
        background-color: #8797ff;
    }

@media only screen and (max-width: 991px) {
    .logo-background{
        display:none;
    }
    .botaosearch{
        margin-right: 20px;
    }
}

@media only screen and (min-width: 992px) {
    .firstlink{
        margin-left: 30px;
    }
}
