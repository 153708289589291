.div-category{
    border-radius: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center; 
}


@media screen and (max-width: 768px) {
    .div-category{
        justify-content: flex-start;
        margin-top: 10px;
        overflow-x: auto;
    
    }
}