.divbackground{
    height:100vh;
    background-image:url("../img/bg-01.jpg");
    position:absolute;
    width:100%;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    justify-content:center;
}
