.button-category{
    padding: 10px 15px;
    border: none;
    background-color: white;
    margin: 15px;
    color: var(--second-color);
    border-radius: 10%;
    transition: 150ms;
    width: 110px;
    cursor: pointer;
    margin-bottom:-5px;
    margin-right:15px;
    margin-top:-5px;
    height:100px;
}
.button-category img{
    width: 60%;
}
.button-category p{
    margin-top: 8px;
    font-size: medium; 
    color: var(--darkergrey);   
}

.button-category:hover{
    scale: 1.03;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 10px -5px, rgba(0, 0, 0, 0.3) 0px 8px 10px -8px;    
}

@media screen and (max-width : 880px){
    .button-category{
        margin: 15px;
        padding: 10px 15px;
        width: 120px;
    }

    .button-category p{
        margin-top: 5px;
        font-size: 14px
        
    }

}