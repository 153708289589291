.botao{
    height:60px;
    width: 80px;
    padding-left:26px;
    border-width: 0px;
    background-color: white;
    border-radius: 10px;
}

.ripple{
    border-radius: 10px;
}
.link-filtros{
    margin-left:25px;
    margin-right:25px;
}
.ripple:hover{
    scale: 1.03;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px 2px; 
    border-radius: 10px;
}


figcaption.mantine-Text-root.mantine-Image-caption.mantine-11ncodt{
    color: rgba(0,0,0,.7);
    margin-top: 2px;
    font-size: 13px;
    font-weight:500;
    margin-left:-25px;
    width:80px;
}

@media only screen and (max-width: 991px) {
    .link-filtros{
        margin-right:20px ;
        margin-left:20px ;
    }
  
}