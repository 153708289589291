.div-adm{
    margin: 100px;

    display: flex;
    flex-direction: column;    
}
.div-title{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 760px) {
    .div-adm{
        margin: 100px 10px;
    
    }
    .div-title{
        display: flex;
        flex-direction: column;
        
        align-items: center;
    }
    .div-title>*{
        margin: 10px;
    }
}