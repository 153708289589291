.map-container{
    margin:auto;
    
    height:400px;
    width:45%;
    border-radius: 10px;

}

.info{
    margin:auto;
    
    background-color: white;
    height:400px;
    width:45%;
    box-shadow: 0px 0px 10px -3px rgb(0, 0, 0,0.5);
    border-radius: 10px;
    text-align: center;
    padding:20px;
}

.info>h1{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 30px;
}
.info>p{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
}
.div-content{
    display:flex;
    height:400px;
}
.titulocontato{
    font-size:50px;
}

.div-contato{
    background-image:url("../img/bg-01.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    justify-content:center;
    height:100vh;
    padding-top: 200px;
}

@media only screen and (max-width: 991px) {
    .div-content{
        display:block;
    }
    .div-contato{
        padding-top:80px;
    }

    .map-container{
        width:90%;
        height:300px;
        margin-bottom: 30px;
    }
    .info{
        width:90%;
        height:auto;
        padding:30px;
    }
    .info>h1{
        margin-bottom:10px;
        margin-top:0px;
    }
    .info>p{
    width:80%;
    font-size:20px;
    margin:auto;
    margin-top:20px;
    }
}
