.div-filtros{
    background-color: rgb(255, 255, 255);
    max-width:90%;
    height: 80px;
    margin-top:100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
    box-shadow: 0px 0px 10px -3px rgb(0, 0, 0,0.5);
    padding: 2px;
    text-align: center;
    justify-content: flex-start;
    overflow-x: auto;

}

.settings{
    margin-left: 40px;
    margin-right: 0px;

}


