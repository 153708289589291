.display-produtos{
    box-shadow: 0px 0px 10px -3px rgb(0, 0, 0,0.5);
    padding-top:25px;
    padding-right:10px;
    padding-left: 10px;
    padding-bottom: 24px;
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 30px;
    border-radius: 10px;
    margin-bottom: 40px;
    background-color: white;
}

.div-products{
   
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    min-height:60vh;

}

.head-products{
    display: flex;
    flex-direction: row;
    margin: 0px 20px 5px 20px;
    justify-content: space-between;
   
}
.head-products>h1{
    outline: none;
    margin:10px;
    font-size: 25px;
    color: rgba(0,0,0,0.7);

}

.img-order{
    margin-right: 10px;
}

@media only screen and (max-width: 575px) {
    .display-produtos{
        margin-right:15px;
        margin-left:15px;
    }
}
