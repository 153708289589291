
.container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    overflow:hidden;
    margin-bottom:50px;
    z-index: 1;
    
}

.leftcolumn {
    width: 60%;
    margin-top: 60px;
    text-align: center;
    position: relative;   
    float:left;      
}
   
.rightcolumn {
    width: 40%;
    margin-top: 60px;
    justify-content: space-between;
    float:left;
}

.imgcarousel{
    width: 95%;
    text-align: center;
}


.imgcarousel img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}


.productdescription {
    border-bottom: 1px solid var(--lightergrey);
    margin-bottom: 20px;
}

.divtags{
    margin-bottom: 30px;
    border-bottom: 1px solid var(--lightergrey);
}

.divtags>span{
    font-size: 15px;
    color: var(--darkgrey);

}
.tags{
    margin-top: 20px;
    margin-bottom: 30px;
}
.tags>*{
    margin-right: 10px;
}
.productdescription span {
    font-size: 12px;
    color: var(--first-color);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
}
.productdescription h1 {
    font-weight: 300;
    font-size: 44px;
    color: var(--darkergrey);
    letter-spacing: -2px;
    margin-bottom: 30px;
    margin-top:10px;
  }
.productdescription p {
    font-size: 18px;
    white-space: pre-wrap;
    font-weight: 300;
    color: var(--darkgrey);
    line-height: 24px;
    margin-bottom: 20px;
}

.productprice {
    display: flex;
    align-items: center;
}
   
.productprice>span {
    font-size: 26px;
    font-weight: 300;
    color: #43474D;
    margin-right: 20px;
}

@media screen and (max-width: 770px) {
    .leftcolumn{
        float: none;
        margin-right:0;
        margin-top: 0;
        width:auto;
        border:0;
    }
    .rightcolumn{
        float: none;
        margin-right:0;
        width:auto;
        border:0;
    }
    .imgcarousel{
        width:auto;
        margin-bottom:-10px;
    }

    .container{

        margin-bottom:10px;
    }
   
    
}