
  table{
    border-collapse: collapse;
    margin: 25px auto;
    
    font-size: 0.9em;
    min-width: 80%;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  }

  .divtable{
    overflow-x: auto;

  }

  table thead tr{
    background-color: var(--first-color);
    color: var(--white);
    text-align: left;
    font-weight: bold;
  }

  table th,
  table td{
    padding: 12px 15px;

  }

  table tbody tr{
    border-bottom: 1px solid var(--lightergrey);
  }

  table tbody tr:nth-of-type(even){
    background-color: var(--lightergrey);

  }

  table tbody tr:last-of-type{
    border-bottom:2px solid var(--first-color);

  }

  table tbody tr img{
    max-width: 140px;
    max-height: 90px;
  }
  
