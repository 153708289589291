:root{
  --first-color:#007ea7 ;
  --second-color:#003459;
  --third-color:#00171f;
  --fourth-color: #28b2c7;
  --transparent:transparent;
  --white: #fff;
  --lightergrey: #eee;
  --lightgrey: #ddd;

  --grey: #ccc;
  --darkgrey: #7e8a94;
  --darkergrey: #333;
  --black: #000;
}

*::-webkit-scrollbar {
  display: none;
}

.unstyled-button{
  background-color: white;
  border:none;
}


input:focus{
  outline: none;
}

a{
  color:inherit;
}
