

.card-div{
    width: calc(20% - 20px);
    height: 400px;
    margin: 10px;
}
@media screen and (max-width : 1279px){
    .card-div{
        width: calc(25% - 20px);
        margin: 10px;
    }
}
@media screen and (max-width : 1017px){
    .card-div{
        width: calc(33% - 20px);
        margin: 10px;
    }
}
@media screen and (max-width : 770px){
    .card-div{
        width: calc(50% - 20px);
        margin: 10px;
    }
}
@media screen and (max-width : 536px){
    .card-div{
        width: calc(100% - 100px);
        margin: 10px 50px;
    }
}

@media screen and (max-width : 390px){
    .card-div{
        width: calc(100% - 40px);
        margin: 10px 20px;
    }
}